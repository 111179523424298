import React, {useEffect, useState} from 'react';
import Header from './header/header';
import LoginBar from './login/loginBar';
import Content from './content/content';
import Footer from './footer';
import { Provider } from 'react-redux';
// import { formDialog } from 'assets/js/dialog';
import store from './store';
import {BrowserRouter as Router,Route} from 'react-router-dom';
import QuestionBreadsHoc from './hoc/QuestionBreadsHoc';



import bgSrc from "./assets/images/zhanhuoBackground.jpg";
import mnzlSrc from './assets/images/mnzlBackground.jpg';
import dsjySrc from './assets/images/dsjyBackground.png';
import tmmnzlSrc from './assets/images/tmmnzlBackground.png';
import bbtsaxcSrc from './assets/images/bbtsaxcBackground.png';
import skdzzSrc from './assets/images/skdzzBackground.jpg';

const App = (props) => {
    const [mark,setMark] = useState();
    useEffect(()=>{
        let params = new URLSearchParams(window.location.search);
        console.log(params.get('marks'));
        if(!params.get('mark')){
            window.location.href = `https://gm.game.360.cn/`;
        }
        setMark(params.get('mark'));
    },[]);
    // let isShowCover = window.sessionStorage.getItem('isShowCover');
    // if (isShowCover) {
    //     store.dispatch({
    //         type: 'SET_COVER',
    //         isShowCover: false
    //     })
    // }
    // formDialog.success('提交成功，我们会在1-3个工作日内回复您，请在“我的问题”列表查看处理进度。', () => {})

    const [bkc,setBkc] = useState('#F3F4F9');
    useEffect(()=>{
      if (['starworld-70b82b05626acebf7608c85834ad654a',
        'starworld-4d869fd5b900b6fc1cb0c5bc8f6873a4',
        'starworld-f08eb42647e2aa91a5c729e547ba49b3',
        'starworld-2af8982d2789a9b475753749b9ccf6b0',
        'starworld-f9a290e3af46f3e1e68ec294d8335248',
        'starworld-3332f098f975c5706fcece81fdfcfcaf'
      ].includes(mark)) {
        setBkc('transparent')
      }
    },[mark]);
    return (
        <Router>
            <Provider store={store}>
                <style jsx>{`
                    .container {
                        background: #F3F4F9;
                        color: #333;
                    }
                    
                    .zhanhuo-bk{
                      background-repeat: no-repeat;
                      position: fixed;
                      top: 0;
                      left: 0;
                      bottom: 0;
                      right: 0;
                      z-index: -99;
                    }
                `}
                </style>
                {
                    mark && mark === 'starworld-70b82b05626acebf7608c85834ad654a' && <div className="zhanhuo-bk"
                    style={{backgroundImage:`url('${bgSrc}')`}}
                  ></div>
                }
                {
                  mark && mark === 'starworld-4d869fd5b900b6fc1cb0c5bc8f6873a4' && <div className="zhanhuo-bk"
                                                                                        style={{backgroundImage:`url('${mnzlSrc}')`}}
                  ></div>
                }
                {
                    mark && mark === 'starworld-f08eb42647e2aa91a5c729e547ba49b3' && <div className="zhanhuo-bk"
                                                                                          style={{backgroundSize: 'cover',backgroundImage:`url('${dsjySrc}')`}}
                    ></div>
                }
                {
                  mark && mark === 'starworld-2af8982d2789a9b475753749b9ccf6b0' && <div className="zhanhuo-bk"
                                                                                        style={{backgroundSize: 'cover',backgroundImage:`url('${tmmnzlSrc}')`}}
                  ></div>
                }

                {
                  mark && mark === 'starworld-f9a290e3af46f3e1e68ec294d8335248' && <div className="zhanhuo-bk"
                                                                                        style={{backgroundSize: 'cover',backgroundImage:`url('${bbtsaxcSrc}')`}}
                  ></div>
                }

              {
                mark && mark === 'starworld-3332f098f975c5706fcece81fdfcfcaf' && <div className="zhanhuo-bk"
                                                                                      style={{
                                                                                        backgroundSize: 'cover',
                                                                                        backgroundImage: `url('${skdzzSrc}')`
                                                                                      }}
                ></div>
              }

              <section className="container" style={{background: bkc}}>
                <LoginBar mark={mark}/>
                <Header mark={mark}/>
                <Route path="/" component={Content}>
                    </Route>

                </section>
                <Footer mark={mark} style={{
                    position: 'absolute',
                    bottom: 0
                }}/>
            </Provider>
        </Router>

    )
}

export default QuestionBreadsHoc(App)