import React from 'react'

const Image2 = (props) => {
    return (
        <section>
            <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>编组 7备份</title>
                <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="01-客服-pc" transform="translate(-1006.000000, -530.000000)">
                        <g id="编组-7备份" transform="translate(1006.000000, 530.000000)">
                            <path d="M30,0 C46.5685425,-3.04359188e-15 60,13.4314575 60,30 C60,46.5685425 46.5685425,60 30,60 C13.4314575,60 2.02906125e-15,46.5685425 0,30 C-2.02906125e-15,13.4314575 13.4314575,3.04359188e-15 30,0 Z" id="矩形备份-23" fill="#6668B6"></path>
                            <path d="M35.8333333,16.6666667 C43.19713,16.6666667 49.1666667,22.6362033 49.1666667,30 C49.1666667,37.3637967 43.19713,43.3333333 35.8333333,43.3333333 L24.1666667,43.3333333 C16.80287,43.3333333 10.8333333,37.3637967 10.8333333,30 C10.8333333,22.6362033 16.80287,16.6666667 24.1666667,16.6666667 L35.8333333,16.6666667 Z M39.1666667,24.1666667 C37.7859548,24.1666667 36.6666667,25.2859548 36.6666667,26.6666667 L36.6666667,26.6666667 L36.6663333,27.4996667 L35.8333333,27.5 C34.4526215,27.5 33.3333333,28.6192881 33.3333333,30 C33.3333333,31.3807119 34.4526215,32.5 35.8333333,32.5 L35.8333333,32.5 L36.6663333,32.4996667 L36.6666667,33.3333333 C36.6666667,34.7140452 37.7859548,35.8333333 39.1666667,35.8333333 C40.5473785,35.8333333 41.6666667,34.7140452 41.6666667,33.3333333 L41.6666667,33.3333333 L41.6663333,32.4996667 L42.5,32.5 C43.8807119,32.5 45,31.3807119 45,30 C45,28.6192881 43.8807119,27.5 42.5,27.5 L42.5,27.5 L41.6663333,27.4996667 L41.6666667,26.6666667 C41.6666667,25.2859548 40.5473785,24.1666667 39.1666667,24.1666667 Z M21.6666667,24.1666667 C18.4450056,24.1666667 15.8333333,26.778339 15.8333333,30 C15.8333333,33.221661 18.4450056,35.8333333 21.6666667,35.8333333 C24.8883277,35.8333333 27.5,33.221661 27.5,30 C27.5,26.778339 24.8883277,24.1666667 21.6666667,24.1666667 Z M21.6666667,27.5 C23.0473785,27.5 24.1666667,28.6192881 24.1666667,30 C24.1666667,31.3807119 23.0473785,32.5 21.6666667,32.5 C20.2859548,32.5 19.1666667,31.3807119 19.1666667,30 C19.1666667,28.6192881 20.2859548,27.5 21.6666667,27.5 Z" id="形状结合" fill="#FFFFFF"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </section>
    )
}

export default Image2