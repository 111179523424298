import React, {useEffect, useState} from 'react';
import { message } from 'antd';
import { connect } from 'react-redux';
import BaseIcon from 'assets/svgIcon/baseIcon';
import quc from './quc';


// .then((e) => {
//    window.QHPass.init({src: 'pcw_i360',signIn: { types: ['quick','normal']}});
// })

function login(dispatch) {
   window.QHPass.signIn(function(userInfo) {
      dispatch({
         type: 'SET_LOGIN_STATUS',
         isLogin: userInfo.nickname ? userInfo.nickname : userInfo.username,
      })
      message.success('登录成功');
      window.location.reload();
   });
}

function register(dispatch) {
   window.QHPass.signUp((userInfo) => {
      dispatch({
         type: 'SET_LOGIN_STATUS',
         isLogin: userInfo.nickname ? userInfo.nickname : userInfo.username,
      })
      message.success('注册成功')
   })
}

function logout(dispatch) {
   window.QHPass.signOut(() => {
      dispatch({
         type: 'SET_LOGIN_STATUS',
         isLogin: ''
      })
      message.success('退出成功');
      window.location.reload();
   })
}

const LoginBar = (props) => {
   const { isLogin, dispatch,mark } = props;
   const style = {height: '23px', width: '1200px', position: 'relative',margin: '0 auto'};
   const [loginFontColor,setLoginFontColor] = useState('');
   useEffect(()=>{
      if ([
         'starworld-70b82b05626acebf7608c85834ad654a',
         'starworld-4d869fd5b900b6fc1cb0c5bc8f6873a4',
         'starworld-f08eb42647e2aa91a5c729e547ba49b3',
         'starworld-2af8982d2789a9b475753749b9ccf6b0',
        'starworld-f9a290e3af46f3e1e68ec294d8335248',
        'starworld-3332f098f975c5706fcece81fdfcfcaf'
      ].includes(mark)){
         setLoginFontColor('#fff')
      }
   },[mark]);
   useEffect(() => {
      quc.then((e) => {
         let QHPass = window.QHPass;
         QHPass.init({
            domainList:["starworldgames.com"],
            "src": "mpw_gamecenter",
            "signIn": {
               "types": [ "mobile", "normal" ],
               "signUpTip": "",
               "findPwdTip": "",
               "normal": { "panelTitle": "帐号登录" },
               "thirdPart": { "providers": [] }
            }
         });
         QHPass.setConfig("supportHttps", ["starworldgames.com"]);
         QHPass.getUserInfo((userInfo) => {
            dispatch({
               type: 'SET_LOGIN_STATUS',
               isLogin: userInfo.nickname ? userInfo.nickname : userInfo.username,
            })
         })
      })
   },[dispatch])
   return (
      <section style={style}>
         <style jsx>{`
            .buttons {
               display: flex;
               height: 100%;
               align-items: center;
            //   margin-left: 1098px;
               position: absolute;
               right: 0;
            }
            .vertical {
               margin-left: 14px;
               margin-right: 14px;
            }
            .logout {
               display: flex;
               align-items: center;
               margin-left: 14px;
            }
            .exit {
               color: #666;
               margin-left: 2px;
            }
         `}
         </style>
         {
            !isLogin ? 
            (<section className="buttons">
               <button style={{color:loginFontColor}} className="login" onClick={() => login(props.dispatch)}>登录</button>
               {/*<span className="vertical">|</span>*/}
               {/*<button className="register" onClick={() => register(props.dispatch)}>注册</button>*/}
            </section>) : 
            (<section className="buttons">
               <p style={{color:loginFontColor}}>{isLogin}</p>
               <div style={{color:loginFontColor}} className="logout add-pointer" onClick={() => logout(props.dispatch)}>
                  <BaseIcon iconName="Logout" width={12} height={12} />
                  <p style={{color:loginFontColor}} className="exit">退出</p>
               </div>
            </section>)
         }
      </section>
   )
}

function mapStateToProps(state) {
   return {
       isLogin: state.showReducer.isLogin
   }
}

export default connect(mapStateToProps)(LoginBar)