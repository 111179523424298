import React, {useEffect, useState} from 'react'
// import Logo from '../assets/svgIcon/360logofooter';
import logoUrl from '../assets/images/starlogo.png';
import zhanhuoLogoUrl from '../assets/images/zhanhuoLogo.png';
import mvzlLogoUrl from '../assets/images/mnzlLogo.png';
import tmmnzlLogoUrl from '../assets/images/tmmnzlLogo.png';
import dsjyLogoUrl from '../assets/images/dsjyLogo.png';
import bbtsaxcLogoUrl from '../assets/images/bbtsaxcLogo.png';
import skdzzLogoUrl from '../assets/images/skdzzLogo.png';
const maps = new Map([
    ['/myquiz/quizdetail', '/quizdetail'],
    ['/onlineconsultation', '/gamechoose'],
    ['/onlineconsultationim', '/'],
    ['/accountproblems', '/quikform'],
    ['/accountproblems/accountdetail', '/gamechoose'],
    ['/accountproblems/accountcontent', '/nofkeycontent']
])

const switchQuery = (query, before, after) => {
    return query.replace(before, after);
}

const switchToPhone = () => {
    let location = window.location;
    let name = maps.get(location.pathname);
    let query = location.search;
    if (name) {
        if (name === '/quikform') {
            query = switchQuery(query, 'accountid', 'id')
        }
        if (name === '/nofkeycontent') {
            query = switchQuery(query, 'fid', 'id')
        }
        window.location.href = `//h5.gm.game.360.cn${name}${query}`;
        return;
    }
    window.location.href = `//h5.gm.game.360.cn${location.pathname}${query}`
}

if(/Android|webOS|iPhone|iPod|iPad/i.test(navigator.userAgent)) {
    switchToPhone();
}
const Footer = (props) => {
    const {
        mark
    } = props;

    const [logo,setLogo] = useState();
    useEffect(()=>{
        if(mark){
            if(mark === 'starworld-70b82b05626acebf7608c85834ad654a'){
                setLogo(zhanhuoLogoUrl);
                return;
            }
            if(mark === 'starworld-4d869fd5b900b6fc1cb0c5bc8f6873a4'){
                setLogo(mvzlLogoUrl);
                return;
            }

            if(mark === 'starworld-f08eb42647e2aa91a5c729e547ba49b3'){
                setLogo(dsjyLogoUrl);
                return;
            }
            if(mark === 'starworld-2af8982d2789a9b475753749b9ccf6b0'){
                setLogo(tmmnzlLogoUrl)
                return;
            }
            if(mark === 'starworld-f9a290e3af46f3e1e68ec294d8335248'){
                setLogo(bbtsaxcLogoUrl);
                return;
            }
            if(mark === 'starworld-3332f098f975c5706fcece81fdfcfcaf'){
                setLogo(skdzzLogoUrl);
                return;
            }
            setLogo(logoUrl);
        }
    },[mark]);

    return (
      <section>
         <style jsx>{`
             .footer {
            
                width: 100%;
                height: 204px;
                background: white;
                display: flex;
                font-size: 14px;
                color:#999999;
                min-width: 1200px;
            }
            .footer-content {
                position: relative;
                padding-top:60px;
                min-width: 1200px;
                margin: 0 auto;
            }
            .logoPic{
                width:107px;
                height:30px;
            }
            .logo {
                position: absolute;
                left: 4px;
                top: 50%;
                transform: translateY(-50%);
            }
            .negotiation a, .negotiation button {
                white-space: nowrap !important;
                // cursor: pointer;
            }
            .negotiation {
                // margin-left: 450px;
                height:84px;
                
                position: absolute;
                right: 69px;
            }
            .top {
                width: 887px;
                margin-bottom:2px;
                margin-top:20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            .top a{
                 color:#999999;
            }
            .center {
                width: 887px;
                margin-bottom:2px;
                display: flex;
                flex-direction: row;
     
            }
            .bottom {
                width:387px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap:nowrap;
            }
            .switchToPhone {
                color: #999090;
            }
         `}
         </style>
         <section className="footer">
            <div className="footer-content">
                <div className="logo">
                    {logo && <img className="logoPic" src={logo} style={mark && (mark === 'starworld-f08eb42647e2aa91a5c729e547ba49b3' || mark === 'starworld-2af8982d2789a9b475753749b9ccf6b0')?{width:'auto',height:'48px'}:( mark && (mark === 'starworld-f9a290e3af46f3e1e68ec294d8335248' ||  mark === 'starworld-3332f098f975c5706fcece81fdfcfcaf')?{width:'auto',height:'68px'}:{})}  fill="white" alt=" "/>}
                </div>
                <div className="negotiation">
                    <div className="top">
                        <a  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11000002000006" target="_blank" rel="noopener noreferrer">京公网安备 11000002000006号 </a>
                        <a rel="noopener noreferrer">京ICP备11029981号-10</a>
                        <a href="http://p8.qhimg.com/t01d6f46129b45e3f03.jpg" target="_blank" rel="noopener noreferrer">京网文[2018]9597-828号</a>
                        <a href="https://p0.ssl.qhimg.com/t012c37d83d1ef8be86.jpg" target="_blank" rel="noopener noreferrer">京ICP证 140771号</a>
                        <a href="http://p0.qhimg.com/t0169dceb0c0d596fcd.jpg" target="_blank" rel="noopener noreferrer">北京世界星辉科技有限责任公司</a>
                        {/*<a href="http://wan.360.cn/protocol.html" target="_blank" rel="noopener noreferrer">360游戏服务及许可协议</a>*/}
                        {/*<a href="mailto:game@360.cn">游戏商务合作</a>*/}
                        {/*<a href="http://mis.h5.u.360.cn/wcn/index.htm" target="_blank" rel="noopener noreferrer">游戏组件未成年人家长监护工程</a>*/}
                    </div>
                    <div className="center">
                        <span>抵制不良游戏，拒绝盗版游戏。</span>
                        <span>注意自我保护，谨防受骗上当。</span>
                        <span>适度游戏益脑，沉迷游戏伤身。</span>
                        <span>合理安排时间，享受健康生活  </span>
                        {/*<a href="http://p0.qhimg.com/t014426b49a8ab2477c.jpg" target="_blank" rel="noopener noreferrer">京ICP证140771号</a>*/}
                        {/*<button>京ICP备11014623号-1</button>*/}
                        {/*<a href="http://p8.qhimg.com/t01d6f46129b45e3f03.jpg" target="_blank" rel="noopener noreferrer">京网文[2018]9597-828号</a>*/}
                        {/*<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11000002000006" target="_blank" rel="noopener noreferrer">京公网安备 11000002000006号</a>*/}
                    </div>
                    {/*<div className="bottom">*/}
                    {/*    <span> 商务联系电话 : 010-68608366-8110 </span>*/}
                    {/*    <span> 传真 : 010-68608355</span>*/}
                        {/*<button>Copyright<span role="img" aria-label="">©️</span>2005-2019 360.CN All Rights Reserved 360网页游戏中心</button>*/}
                        {/* <button className="switchToPhone" onClick={switchToPhone}>切换至手机版</button> */}
                    {/*</div>*/}
                </div>
            </div>
        </section>
      </section>
    )
}

export default Footer




