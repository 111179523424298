import React from 'react'

const Image2 = (props) => {
    return (
        <section>
            <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>编组 11</title>
                <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="01-客服-pc" transform="translate(-1006.000000, -378.000000)">
                        <g id="编组-11" transform="translate(1006.000000, 378.000000)">
                            <path d="M30,0 C46.5685425,-3.04359188e-15 60,13.4314575 60,30 C60,46.5685425 46.5685425,60 30,60 C13.4314575,60 2.02906125e-15,46.5685425 0,30 C-2.02906125e-15,13.4314575 13.4314575,3.04359188e-15 30,0 Z" id="矩形备份-24" fill="#6668B6"></path>
                            <path d="M29.5,16 L30.5,16 C36.2989899,16 41,20.7010101 41,26.5 L41,35 C41,36.1045695 40.1045695,37 39,37 L21,37 C19.8954305,37 19,36.1045695 19,35 L19,26.5 C19,20.7010101 23.7010101,16 29.5,16 Z" id="矩形" fill="#FFFFFF"></path>
                            <path d="M21,40 L39,40 C40.1045695,40 41,40.8954305 41,42 C41,43.1045695 40.1045695,44 39,44 L21,44 C19.8954305,44 19,43.1045695 19,42 C19,40.8954305 19.8954305,40 21,40 Z" id="矩形" fill="#FFFFFF"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </section>
    )
}

export default Image2