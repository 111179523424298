import React from 'react'

const Image1 = (props) => {
    return (
        <section>
            <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>编组 5</title>
                <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="01-客服-pc" transform="translate(-396.000000, -226.000000)">
                        <g id="编组-5" transform="translate(396.000000, 226.000000)">
                            <path d="M30,0 C46.5685425,-3.04359188e-15 60,13.4314575 60,30 C60,46.5685425 46.5685425,60 30,60 C13.4314575,60 2.02906125e-15,46.5685425 0,30 C-2.02906125e-15,13.4314575 13.4314575,3.04359188e-15 30,0 Z" id="矩形备份-28" fill="#6668B6" opacity="0.900000036"></path>
                            <circle id="椭圆形" fill="#FFFFFF" cx="30.5" cy="22.5" r="7.5"></circle>
                            <path d="M24,33 L37,33 C40.3137085,33 43,35.6862915 43,39 C43,42.3137085 40.3137085,45 37,45 L24,45 C20.6862915,45 18,42.3137085 18,39 C18,35.6862915 20.6862915,33 24,33 Z" id="矩形" fill="#FFFFFF"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </section>
    )
}

export default Image1