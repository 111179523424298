import React, {useEffect, useState} from 'react';
import Starlogo from '../assets/svgIcon/starlogo';
import logoUrl from '../assets/images/starlogo.png';
import zhanhuoLogoUrl from '../assets/images/zhanhuoLogo.png';
import mnzlLogoUrl from '../assets/images/mnzlLogo.png';
import tmmnzlLogoUrl from '../assets/images/tmmnzlLogo.png';
import dsjyLogoUrl from '../assets/images/dsjyLogo.png'
import bbtsaxcLogoUrl from '../assets/images/bbtsaxcLogo.png';
import skdzzLogoUrl from '../assets/images/skdzzLogo.png';
import { withRouter } from 'react-router';


function Header(props) {
    const {
        mark
    } = props;
    // const sections = ['首页','个人中心','页游','手游'];
    const sections = ['我的问题'];
    const mapTo = new Map([
        // [1, '//i.360.cn'],
        // [2, 'http://wan.360.cn'],
        // // [3, 'http://youxi.com'],
        // [3, 'http://u.360.cn'],
        // // [5, '/']
    ])
    const [ count ] = useState(0);
    const [logo,setLogo] = useState();
    useEffect(()=>{
        if(mark){
            if(mark === 'starworld-70b82b05626acebf7608c85834ad654a'){
                setLogo(zhanhuoLogoUrl);
                return;
            }
            if(mark === 'starworld-4d869fd5b900b6fc1cb0c5bc8f6873a4'){
                setLogo(mnzlLogoUrl);
                return;
            }

            if(mark === 'starworld-f08eb42647e2aa91a5c729e547ba49b3'){
                setLogo(dsjyLogoUrl);
                return;
            }
            if(mark === 'starworld-2af8982d2789a9b475753749b9ccf6b0'){
                setLogo(tmmnzlLogoUrl)
                return;
            }
            if(mark === 'starworld-f9a290e3af46f3e1e68ec294d8335248'){
                setLogo(bbtsaxcLogoUrl);
                return;
            }

            if (mark === 'starworld-3332f098f975c5706fcece81fdfcfcaf'){
                setLogo(skdzzLogoUrl);
                return;
            }
            setLogo(logoUrl);
        }
    },[mark]);
    const goHeaderRoute = (index, history) => {
        // setCount(index);
        // if (index === 5) return;
        if (index === 0 ) {
            props.history.push('/myquiz');
            return;
        }
        const url = mapTo.get(index);
        window.open(url);
    }
    const toHome = () => {
        props.history.push('/')
    }
    return (
        <section>
            <style jsx>{`
                .header-container {
                    height: 80px;
                    // margin-top:-24px;
                    background-color:white;
                    display:flex;
                    align-items:center;
                    position: relative;
                    min-width: 1200px;
                }
                .logoPic{
                    width:107px;
                    height:30px;
                }
                .header-center {
                    position: relative;
                    min-width: 1200px;
                    height: 100%;
                    margin:0 auto;
                }
                .logo {
                    // margin-left: 150px;
                    position: absolute;
                    width: 360px;
                    left: 5px;
                    top: 50%;
                    transform: translateY(-50%);
                    line-height: 0;
                }
                .logo-bg{
                     opacity: 0.10;
                     width: 112px;
                     height: 80px;
                     position: absolute;
                     left: 260px;
                      background: #ea4236;
                      white-space: nowrap;
                    top: 49%;
                    transform: translateY(-50%);
                    z-index:999;
                }
                .logo-right {
                    z-index:1001;
                    line-height:80px;
                    text-align:center;
                    font-family: Microsoft YaHei;
                    font-size: 16px;
                    color: #EA4236;
                    width: 112px;
                     white-space: nowrap;
                    top: 49%;
                    left: 260px;
                    transform: translateY(-50%);
                     position: absolute;
                }
                .sections {
                    display: flex;
                    align-items: center;
                    // margin-left: 394px;
                    top: 50%;
                    transform: translateY(-50%);
                    position: absolute;
                    right: -4px;
                    // right: 360px;
                }
                // .sections .section {
                //     font-size: 16px;
                //     margin-right: 18px;
                    font-family: Microsoft YaHei;
                   font-size: 20px;
                    color: #333333;
                //     cursor: pointer;
                //     font-weight: bold;
                //     color: white;
                // }
                .sections .section-active {
                    display:inline-block;
                    width:80px;
                    height:28px;
                    line-height: 28px;
                    text-align:center;
                    margin-right: 18px;
                    cursor: pointer;
                    text-shadow: 0 0 5px #fff;
                }
                .sections .section-active:hover {
                  color:#EA4236;
                }
                .red {
                    color: #D0021B;
                }
            `}</style>
            <div className="header-container"
                 // style={{backgroundImage:'url('+imgUrl+')',backgroundSize: '100% 100%'}}
            >
                <section className="header-center">
                    <div className="logo add-pointer" onClick={toHome}>
                        {logo && <img className="logoPic"  style={mark && (mark === 'starworld-f08eb42647e2aa91a5c729e547ba49b3' || mark === 'starworld-2af8982d2789a9b475753749b9ccf6b0' )?{width:'auto',height:'48px'}:( mark && (mark === 'starworld-f9a290e3af46f3e1e68ec294d8335248' || mark === 'starworld-3332f098f975c5706fcece81fdfcfcaf')?{width:'auto',height:'68px'}:{})} src={logo}  fill="white" alt=" "/>}
                        <div className="logo-bg"></div>
                         <span className="logo-right">客服中心</span>
                    </div>
                    {/*<ul className="sections">*/}
                    {/*    {sections.map((item,index) => {*/}
                    {/*        return (*/}
                    {/*        <li*/}
                    {/*            className={count === index ? 'section-active' : 'section'}*/}
                    {/*            key={item} */}
                    {/*            onClick={() => goHeaderRoute(index, props.history)} */}
                    {/*            // style={{fontWeight: count === index ? 'bold' : 'normal'}}*/}
                    {/*        >*/}
                    {/*            {item}*/}
                    {/*        </li >)*/}
                    {/*    })}*/}
                    {/*</ul>*/}
                    <div className="sections">
                    <a className="section_active"  onClick={() => goHeaderRoute(0, props.history)} >我的问题</a>
                    </div>
                </section>

            </div>
        </section>

    )
}

export default withRouter(Header)