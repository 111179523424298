import React from 'react'

const AddMore = (props) => {
    const { width, height } = props
    return (
      <section>
         <style jsx>{`
             
         `}
         </style>
        <svg style={{transform: 'skew(20deg)'}} width={width+'px'} height={height+'px'} viewBox="0 0 42 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>more</title>
            
            <defs></defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="首页-已登录" transform="translate(-776.000000, -858.000000)" fill="#999999">
                    <g id="Group-5" transform="translate(603.000000, 595.000000)">
                        <g id="more" transform="translate(173.000000, 260.000000)">
                            <text id="更多" fontFamily="PingFangSC-Medium, PingFang SC" fontSize="14" fontWeight="400" letterSpacing="0.349848">
                                <tspan x="0" y="15">更多</tspan>
                            </text>
                            <polygon id="Disclosure-Indicator-Copy" points="34 5.5 35.5 4 42 10.5 35.5 17 34 15.5 39 10.5"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
      </section>
    )
}
                          
export default AddMore