import React, {useEffect} from 'react';
import { Route } from "react-router-dom";
import { connect } from 'react-redux';
import LeftPages from './leftPages';
import CenterPages from './centerPages';
import RightPages from './rightPages';
import imgUrl from 'assets/images/know.png';
import spiral from 'assets/images/spiral.png';
import DetailServices from './detailServices';
// import { fetchData } from 'helpers';
// import QuizDetail from 'content/detailServices/myquiz/quizDetail';

//
// const Cover = ({ dispatch }) => {
//     window.sessionStorage.setItem('isShowCover', '1')
//     let know = () => {
//         dispatch({
//             type: 'SET_SUBMIT_COVER',
//             submitCover: false
//         })
//     }
//     return (
//     <section>
//         <style jsx>{`
//             .cover{
//                 position: fixed;
//                 left: 0;
//                 top: 0;
//                 right: 0;
//                 bottom: 0;
//                 background: rgba(0,0,0,0.4);
//                 z-index: 10;
//                 overflow-y: hidden;
//             }
//             .zhanwei {
//                 width: 242px;
//                 height: 300px;
//                 // position: absolute;
//             }
//             .center {
//                 width: 1200px;
//                 position: absolute;
//                 // left: 16vw;
//                 top: 156px;
//                 z-index: 20;
//                 display: flex;
//                 // justify-content: space-between;
//             }
//             .tip {
//                 width: 280px;
//                 height: 92px;
//                 background: rgba(0,0,0,0.6);
//                 color: white;
//                 border: 2px solid #fff;
//                 font-size: 14px;
//                 text-align: center;
//                 line-height: 2.6;
//                 padding: 6px 0;
//                 // position: absolute;
//                 // left: 32vw;
//                 // top: 206px;
//             }
//             .know {
//                 width: 138px;
//                 height: 40px;
//                 position: absolute;
//                 right: 0;
//                 // z-index: 20;
//                 // right: 18vw;
//                 // top: 206px;
//             }
//             img {
//                 width: 100%;
//                 height: 100%;
//             }
//             .spiral {
//                 width: 72px;
//                 height: 68px;
//                 position: absolute;
//                 top: 100px;
//                 left: 400px;
//             }
//         `}
//         </style>
//         <section className="cover">
//         </section>
//         <div className="center">
//         <div className="zhanwei"></div>
//             <div className="tip">
//                 点击标题，提交问题<br /> 问题解决更快哦~
//             </div>
//             <div className="know" onClick={know}>
//                 <img src={imgUrl} alt=''/>
//             </div>
//             <div className="spiral">
//                 <img src={spiral} alt=''/>
//             </div>
//         </div>
//     </section>
//     )
// }


let toolSearch = (function() {
    // let urlSearch = window.location.search;
    let params = new URLSearchParams(window.location.search);
    let searchs = ['pname', 'pkey', 'gkey', 'gname', 'skey', 'sname', 'account',"mark"];
    let url = '';
    searchs.forEach((item) => {
        let res = params.get(item);
        if (res) {
            url += '&' + item + '=' + res
        }
    })
    // if (url && !urlSearch.includes('?')) url.replace('&', '?')
    return url;
})()

const Content = (props) => {


    useEffect(()=>{
        let { push } = props.history;
        props.history.push = (values) => {
            let { pathname } = window.location;
            let ma = values.match(/\/(.+)\??/);
            if (!ma && pathname === '/') return;
            if (ma && pathname !== '/' && ma === pathname.split('/')[1]) {
                return;
            }
            let url = values + toolSearch;
            if (toolSearch && !url.includes('?')) {
                url = url.replace('&', '?');
            }
            push(url);
        }
    },[]);

    let isIndex = window.location.pathname === '/';
    let showCover = props.submitCover || !window.sessionStorage.getItem('isShowCover');
    return (
        <section style={{minHeight: '526px'}}>
            <style jsx>{`
                .content-container {
                    margin: 0 auto;
                  padding-top:40px;
                  padding-bottom:40px;
                    width: 1200px;  
                    display: flex;
                    justify-content:center;
                    // background: linear-gradient(#fff, #f2f2f2);
                    // position: relative;
                }
            `}
            </style>
            <section className="content-container">
                {/*{showCover && isIndex && <Cover dispatch={props.dispatch} /> }*/}
                {/*<LeftPages />*/}
                <Route exact path="/" component={CenterPages} />
                <Route path="/:question" component={DetailServices} />
                <RightPages />
            </section>

        </section>
    )
}

function mapStateToProps(state) {
    return {
        isShowOnlineService: state.showReducer.isShowOnlineService,
        isShowCover: state.showReducer.isShowCover,
        submitCover: state.showReducer.submitCover
    }
}

export default connect(mapStateToProps)(Content)