import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router';
import imgUrl from 'assets/images/talkIcon.png';
import imgUrl2 from 'assets/images/consultFont.png';
import {connect} from 'react-redux';
import Draggable from 'react-draggable';
import { fetchData } from "../../helpers";






const CustomerService = (props) => {
  const {location} = props.history;
  let pathname = location.pathname;
  let search = location.search;
  const style = {
    position: 'absolute',
    cursor: 'pointer',
    top: '60%',
    right: '0px',
    zIndex: '9999',
    // display: pathname === '/onlineconsultation' && search.includes('fkey') ? 'none' : 'block',
  }
  const [isDrag, setIsDrag] = useState(false);
  const [robotLink,setRobotLink] = useState(null);

  const toOnlineConsultation = (history) => {
    // console.log('click');
    // return;
    window.open(robotLink);
    return;
    // window.location.href = 'https://zhike.help.360.cn/?rid=c1ae0d32-bcc1-4855-b937-886783ce6423'
  }

  useEffect(()=>{
    fetchData("getWebsiteDetail")
      .then(result => {
        if (result) {
          console.log(result);
          if(result && result.data && result.data.robot_link){
            setRobotLink(result.data.robot_link);
          }
        }
      })
      .catch(err => {});
  },[]);

  return (
    <Draggable
      onStart={() => {
        setIsDrag(false);
      }}
      onDrag={() => {
        setIsDrag(true);
      }}
      onStop={() => {
        setTimeout(()=>{
          setIsDrag(false);
        },200);
      }}>
      <section style={style} onClick={() => isDrag ? void (0) : toOnlineConsultation(props.history)}
      >
        <style jsx>{`
             .top {
                height: 38px;
                font-size: 14px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                background: linear-gradient(#DF343E, #B81C25);
             }
           
             .top h6 {
                font-size: 14px;
             }
             .title {
                 color: white;
             }
             .avatar {
                //  padding: 5px 14px;
                width: 80px;
                height: 80px;
             }
             .bottom:hover{
             background-color:#DE362A
             }
             .bottom {
             position:relative;
                 height: 149px;
                 width: 50px;
                 background: #ea4236;
                 border-top-left-radius: 8px;
                 border-bottom-left-radius:8px;
             }
             .bottom span{
             display:inline-block;
           position:absolute;
           margin-top:49px;
           margin-left:14px;
             width:16px;
             height:80px;
             font-family: Microsoft YaHei;
             font-size: 16px;
             color: #ffffff;
             line-height: 20px;
             }
             .avatar img, .bottom img {
             position:absolute;
             margin-left:12px;
             margin-top:20px;
                width: 20px;
                height: 21px;
            }
         `}
        </style>
        {/* <div className="top">
            <h6 className="title">在线客服</h6>
         </div> */}
        {/*<div className="avatar">*/}
        {/*  <img src={imgUrl} alt='' onMouseDown={(event) => {*/}
        {/*    event.preventDefault()*/}
        {/*  }}*/}
        {/*       onMouseMove={(event) => {*/}
        {/*         event.preventDefault()*/}
        {/*       }} onMouseUp={(event) => {*/}
        {/*    event.preventDefault()*/}
        {/*  }}/>*/}
        {/*</div>*/}
        {robotLink?<div className="bottom add-pointer"  alt='' onMouseDown={(event) => {
            event.preventDefault()
        }}
             onMouseMove={(event) => {
                 event.preventDefault()
             }} onMouseUp={(event) => {
            event.preventDefault()
        }}>
            <img src={imgUrl}/>
            <span>AI客服</span>
          {/*<img src={imgUrl2} alt='' onMouseDown={(event) => {*/}
          {/*  event.preventDefault()*/}
          {/*}}*/}
          {/*     onMouseMove={(event) => {*/}
          {/*       event.preventDefault()*/}
          {/*     }} onMouseUp={(event) => {*/}
          {/*  event.preventDefault()*/}
          {/*}}/>*/}
        </div>:null}
      </section>
    </Draggable>

  )
}

function mapStateToProps(state) {
  return {
    isShowOnlineService: state.showReducer.isShowOnlineService
  };
}

export default connect(mapStateToProps)(withRouter(CustomerService));