import React ,{useEffect} from "react";
import { Form, Select, Button } from "antd";
// import $ from "jquery";
import { fetchData } from "helpers";

const { Option } = Select;

let timeout;
// let currentValue;

class OnlineConsultation extends React.Component {
  constructor(props) {
    super(props);
    this.search = new URLSearchParams(props.location.search);
    this.state = {
      isNameDisabled: true,
      platform: "",
      gameName: "",
      gameNameGkey: "", // 所指定的选中条目
      zoneSkey: '',
      nameSelectData: [], // 游戏名称列表
      gameZoneList: [], // 区服列表
      latelyGame: '',
      latelyGames: [], // 近期玩的游戏列表
      noToolbarParams: [
        {
          urlname: 'pname',
          key: 'pkey',
          stateKey: 'platform',
          name: 'platform'
        },
        {
          urlname: 'gname',
          key: 'gkey',
          stateKey: 'gameNameGkey',
          name: 'gameName'
        },
        {
          urlname: 'sname',
          key: 'skey',
          stateKey: 'zoneSkey',
          name: 'zoneServer'
        },
      ],
    };
  }
  componentDidMount() {

    this.props.history.push(
      `/accountproblems/accountdetail${window.location.search}`
    );
    return;

    const search = this.props.location.search;

    if (search) {
      let gameName = this.search.get("gamename");
      if (gameName && gameName === "gamehall") {
        this.setState({
          isNameDisabled: false,
          platform: "wan",
          gameName: gameName
        });
      }
      this.state.noToolbarParams.forEach((item, index) => {
        // url上对应的value ---name
        var name = this.search.get(item.urlname);
        if (name) {
          this.setState({
            [item.stateKey]: this.search.get(item.key),
          })
          this.props.form.setFieldsValue({[item.name]: name})
        }
      })
    }
  }
  // handleLatelyGameSelect = value => {
  //   let lates = this.state.latelyGames.find(i => value === i.gkey);
  //   let nameSelectData = [{ name: lates.gname, gkey: lates.gkey}];
  //   let gameZoneList = [{ name: lates.zname, skey: lates.skey}];
  //   this.setState({
  //     platform: lates.pkey,
  //     gameNameGkey: lates.gkey,
  //     zoneSkey: lates.skey,
  //     nameSelectData,
  //     gameZoneList
  //   }, (state) => {
  //     this.props.form.setFieldsValue({ platform: lates.pkey});
  //   })
  // };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const options = {
          pkey: values.platform !== "gamehall" ? values.platform : "wan",
          gkey: values.platform !== "gamehall" ? values.gameName : "gamehall",
          skey: values.zoneServer ? String(values.zoneServer) : ""
        };
        let fkey = this.search.get("fkey");
        if (fkey) {
          // let quizTitle = this.search.get("quizTitle");
          window.sessionStorage.setItem(
            "gameHallFormOptions",
            JSON.stringify(options)
          );
          this.props.history.push(
            `/accountproblems/accountdetail${window.location.search}`
          );
          return;
        }
      }
    });
  };
  handlePlatformSelect = value => {
    if (value && value !== this.state.platform) {
      this.setState({
        gameNameGkey: '',
        latelyGame: '',
        zoneSkey: '',
        platform: value,
        nameSelectData: [],
        gameZoneList: []
      });
      this.props.form.resetFields(["zoneServer", "latelyGames"]);
    }
  };
  fetchSearch(value, callback) {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    // currentValue = value;
    const pkey = this.state.platform;
    function fake() {
      fetchData("ajaxGameSearch", {
        pkey,
        name: value
      })
        .then(result => {
          if (result) {
            callback(result.data);
          }
        })
        .catch(err => {});
    }

    timeout = setTimeout(fake, 500);
  }
  handleSearch = value => {
    if (value) {
      this.fetchSearch(value, data => this.setState({ nameSelectData: data }));
    } else {
      this.setState({ data: [] });
    }
  };

  handleNameChange = value => {
    this.setState(() => ({
      gameNameGkey: value
    }));
    if (this.state.platform === "mobile") return;
    fetchData("ajaxZoneSearch", {
      pkey: this.state.platform,
      gkey: value
    })
      .then(result => {
        if (result) {
          this.setState({
            gameZoneList: result.data
          });
        }
      })
      .catch(err => {});
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const style = {
      width: "100%",
      background: "white",
      border: "1px solid #e9e9e9",
      padding: "60px 0 34px",
      boxSizing: "content-box",
      position: 'relative'
    };
    return (
      <section style={style} className="consult-form">
        <style>
          {`
            .consult-form .ant-form-item {
              margin-bottom: 8px;
            }
            .consult-form .ant-input,.ant-select-selection {
              border-radius: 2px;
            }
            .consult-form .ant-input {
              height: 32px;
            }
            .consult-form .ant-btn-primary {
              width: 104px;
              height: 30px;
              background: #D0021B;
              border-color: #D0021B;
              border-radius: 0;
            }
            .consult-form .ant-select-selection--single {
              height: 32px;
            }
            .consult-form .ant-select-selection__rendered {
              line-height: 32px;
            }
            .online-explanation {
              width:280px;
              height:88px;
              padding: 12px;
              // padding-right: 4px;
              line-height: 20px;
              background:rgba(255,243,243,1);
              border-radius:5px;
              border:1px dashed rgba(255,203,203,1);
              font-size: 12px;
              position: absolute;
              right: 20px;
              top: 62px;
            }
          `}
        </style>
        <Form
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 6 }}
          onSubmit={this.handleSubmit}
        >
          <Form.Item label="游戏平台">
            {getFieldDecorator("platform", {
              rules: [{ required: true, message: "请选择游戏平台" }],
              initialValue: this.state.platform
                ? this.state.platform
                : undefined
            })(
              <Select
                placeholder="请选择游戏平台"
                onChange={this.handlePlatformSelect}
              >
                <Option value="wan">页游平台</Option>
                <Option value="mobile">手游平台</Option>
                <Option value="youxi">端游平台</Option>
              </Select>
            )}
          </Form.Item>
          {
            <Form.Item label="游戏名称">
              {getFieldDecorator("gameName", {
                rules: [{ required: true, message: "请输入游戏名称" }],
                initialValue:
                  this.state.gameNameGkey ? this.state.gameNameGkey : undefined
              })(
                // <Input placeholder="输入游戏名称" disabled={this.state.isNameDisabled} onChange={this.handleGameNameSelect} />
                <Select
                  showSearch
                  placeholder={"请输入游戏名称"}
                  // style={this.props.style}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  disabled={!this.state.platform}
                  filterOption={false}
                  onSearch={this.handleSearch}
                  onChange={this.handleNameChange}
                  notFoundContent={"暂无搜索数据"}
                >
                  {this.state.nameSelectData.map(item => {
                    return <Option key={item.gkey} value={item.gkey}>{item.name}</Option>;
                  })}
                </Select>
              )}
            </Form.Item>
          }
          <Form.Item
            label="游戏区服"
            style={{
              display: this.state.platform === "mobile" ? "none" : "block"
            }}
          >
            {getFieldDecorator("zoneServer", {
              initialValue: this.state.zoneSkey ? this.state.zoneSkey : undefined
            })(
              <Select
                placeholder="游戏区服"
                onChange={this.handleSelectChange}
                notFoundContent={"暂无搜索数据"}
              >
                {this.state.gameZoneList.map((item, index) => {
                  return (
                    <Option value={item.skey} key={item.skey}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          {/* <Form.Item label="近期玩过的游戏">
            {getFieldDecorator("latelyGames")(
              <Select
                placeholder="请选择近期玩过的游戏"
                onChange={this.handleLatelyGameSelect}
              >
                {this.state.latelyGames.map(item => {
                  return (
                    <Option value={item.gkey} key={item.gkey}>
                      {item.gname}
                    </Option>
                  )
                })}
              </Select>
            )}
          </Form.Item> */}
          <Form.Item
            wrapperCol={{ span: 12, offset: 10 }}
            style={{ marginTop: "26px" }}
          >
            <Button type="primary" htmlType="submit">
              发起咨询
            </Button>
          </Form.Item>
        </Form>
        <section className="online-explanation">
          <i className="red weight">如遇平台问题</i>，游戏平台选【<i className="red weight">页游</i>】，<br /> 游戏名称填写【<i className="red weight">游戏大厅/积分商城/棋牌大厅/H5游戏/G5</i>】发起咨询
        </section>
      </section>
    );
  }
}

const WrappedApp = Form.create({
  name: "coordinated"
})(OnlineConsultation);

export default WrappedApp;
