import React from 'react'

const Image2 = (props) => {
    return (
        <section>
            <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>编组 7</title>
                <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="01-客服-pc" transform="translate(-1006.000000, -682.000000)">
                        <g id="编组-7" transform="translate(1006.000000, 682.000000)">
                            <path d="M30,0 C46.5685425,-3.04359188e-15 60,13.4314575 60,30 C60,46.5685425 46.5685425,60 30,60 C13.4314575,60 2.02906125e-15,46.5685425 0,30 C-2.02906125e-15,13.4314575 13.4314575,3.04359188e-15 30,0 Z" id="矩形备份-26" fill="#6668B6" opacity="0.900000036"></path>
                            <path d="M22.3241342,17 L24.6758658,17 C26.5124445,17 27.1894162,17.1933867 27.8684285,17.5565265 C28.5474409,17.9196662 29.0803338,18.4525591 29.4434735,19.1315715 C29.8066133,19.8105838 30,20.4875555 30,22.3241342 L30,24.6758658 C30,26.5124445 29.8066133,27.1894162 29.4434735,27.8684285 C29.0803338,28.5474409 28.5474409,29.0803338 27.8684285,29.4434735 C27.1894162,29.8066133 26.5124445,30 24.6758658,30 L22.3241342,30 C20.4875555,30 19.8105838,29.8066133 19.1315715,29.4434735 C18.4525591,29.0803338 17.9196662,28.5474409 17.5565265,27.8684285 C17.1933867,27.1894162 17,26.5124445 17,24.6758658 L17,22.3241342 C17,20.4875555 17.1933867,19.8105838 17.5565265,19.1315715 C17.9196662,18.4525591 18.4525591,17.9196662 19.1315715,17.5565265 C19.8105838,17.1933867 20.4875555,17 22.3241342,17 Z" id="矩形" fill="#FFFFFF"></path>
                            <path d="M22.3241342,31 L24.6758658,31 C26.5124445,31 27.1894162,31.1933867 27.8684285,31.5565265 C28.5474409,31.9196662 29.0803338,32.4525591 29.4434735,33.1315715 C29.8066133,33.8105838 30,34.4875555 30,36.3241342 L30,38.6758658 C30,40.5124445 29.8066133,41.1894162 29.4434735,41.8684285 C29.0803338,42.5474409 28.5474409,43.0803338 27.8684285,43.4434735 C27.1894162,43.8066133 26.5124445,44 24.6758658,44 L22.3241342,44 C20.4875555,44 19.8105838,43.8066133 19.1315715,43.4434735 C18.4525591,43.0803338 17.9196662,42.5474409 17.5565265,41.8684285 C17.1933867,41.1894162 17,40.5124445 17,38.6758658 L17,36.3241342 C17,34.4875555 17.1933867,33.8105838 17.5565265,33.1315715 C17.9196662,32.4525591 18.4525591,31.9196662 19.1315715,31.5565265 C19.8105838,31.1933867 20.4875555,31 22.3241342,31 Z" id="矩形备份-3" fill="#FFFFFF"></path>
                            <path d="M36.3241342,17 L42.9317145,17 C43.3031806,17 43.4378832,17.0386773 43.5736857,17.1113053 C43.7094882,17.1839332 43.8160668,17.2905118 43.8886947,17.4263143 C43.9613227,17.5621168 44,17.6968194 44,18.0682855 L44,24.6758658 C44,26.5124445 43.8066133,27.1894162 43.4434735,27.8684285 C43.0803338,28.5474409 42.5474409,29.0803338 41.8684285,29.4434735 C41.1894162,29.8066133 40.5124445,30 38.6758658,30 L36.3241342,30 C34.4875555,30 33.8105838,29.8066133 33.1315715,29.4434735 C32.4525591,29.0803338 31.9196662,28.5474409 31.5565265,27.8684285 C31.1933867,27.1894162 31,26.5124445 31,24.6758658 L31,22.3241342 C31,20.4875555 31.1933867,19.8105838 31.5565265,19.1315715 C31.9196662,18.4525591 32.4525591,17.9196662 33.1315715,17.5565265 C33.8105838,17.1933867 34.4875555,17 36.3241342,17 Z" id="矩形备份" fill="#FFFFFF"></path>
                            <path d="M36.3241342,31 L38.6758658,31 C40.5124445,31 41.1894162,31.1933867 41.8684285,31.5565265 C42.5474409,31.9196662 43.0803338,32.4525591 43.4434735,33.1315715 C43.8066133,33.8105838 44,34.4875555 44,36.3241342 L44,38.6758658 C44,40.5124445 43.8066133,41.1894162 43.4434735,41.8684285 C43.0803338,42.5474409 42.5474409,43.0803338 41.8684285,43.4434735 C41.1894162,43.8066133 40.5124445,44 38.6758658,44 L36.3241342,44 C34.4875555,44 33.8105838,43.8066133 33.1315715,43.4434735 C32.4525591,43.0803338 31.9196662,42.5474409 31.5565265,41.8684285 C31.1933867,41.1894162 31,40.5124445 31,38.6758658 L31,36.3241342 C31,34.4875555 31.1933867,33.8105838 31.5565265,33.1315715 C31.9196662,32.4525591 32.4525591,31.9196662 33.1315715,31.5565265 C33.8105838,31.1933867 34.4875555,31 36.3241342,31 Z" id="矩形备份-10" fill="#FFFFFF"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </section>
    )
}

export default Image2