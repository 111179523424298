import React from 'react'

const Image2 = (props) => {
    return (
        <section>
            <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>编组</title>
                <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="01-客服-pc" transform="translate(-396.000000, -378.000000)">
                        <g id="编组" transform="translate(396.000000, 378.000000)">
                            <path d="M30,0 C46.5685425,-3.04359188e-15 60,13.4314575 60,30 C60,46.5685425 46.5685425,60 30,60 C13.4314575,60 2.02906125e-15,46.5685425 0,30 C-2.02906125e-15,13.4314575 13.4314575,3.04359188e-15 30,0 Z" id="矩形备份-20" fill="#6668B6"></path>
                            <path d="M24.7430463,15 L35.2569537,15 C37.4857505,15 38.2939661,15.232064 39.1087809,15.6678318 C39.9235957,16.1035995 40.5630672,16.7430709 40.9988349,17.5578857 C41.4346026,18.3727006 41.6666667,19.1809162 41.6666667,21.409713 L41.6666667,38.590287 C41.6666667,40.8190838 41.4346026,41.6272994 40.9988349,42.4421143 C40.5630672,43.2569291 39.9235957,43.8964005 39.1087809,44.3321682 C38.2939661,44.767936 37.4857505,45 35.2569537,45 L24.7430463,45 C22.5142495,45 21.7060339,44.767936 20.8912191,44.3321682 C20.0764043,43.8964005 19.4369328,43.2569291 19.0011651,42.4421143 C18.5653974,41.6272994 18.3333333,40.8190838 18.3333333,38.590287 L18.3333333,21.409713 C18.3333333,19.1809162 18.5653974,18.3727006 19.0011651,17.5578857 C19.4369328,16.7430709 20.0764043,16.1035995 20.8912191,15.6678318 C21.7060339,15.232064 22.5142495,15 24.7430463,15 Z" id="矩形备份-25" fill="#FFFFFF"></path>
                            <path d="M30,36.6666667 C31.3807119,36.6666667 32.5,37.7859548 32.5,39.1666667 C32.5,40.5473785 31.3807119,41.6666667 30,41.6666667 C28.6192881,41.6666667 27.5,40.5473785 27.5,39.1666667 C27.5,37.7859548 28.6192881,36.6666667 30,36.6666667 Z" id="矩形备份-27" fill="#6668B6"></path>
                            <path d="M24.8715232,18.3333333 L35.1284768,18.3333333 C36.2428753,18.3333333 36.6469831,18.4493653 37.0543905,18.6672492 C37.4617979,18.8851331 37.7815336,19.2048688 37.9994175,19.6122762 C38.2173013,20.0196836 38.3333333,20.4237914 38.3333333,21.5381898 L38.3333333,29.2951435 C38.3333333,30.4095419 38.2173013,30.8136497 37.9994175,31.2210571 C37.7815336,31.6284645 37.4617979,31.9482003 37.0543905,32.1660841 C36.6469831,32.383968 36.2428753,32.5 35.1284768,32.5 L24.8715232,32.5 C23.7571247,32.5 23.3530169,32.383968 22.9456095,32.1660841 C22.5382021,31.9482003 22.2184664,31.6284645 22.0005825,31.2210571 C21.7826987,30.8136497 21.6666667,30.4095419 21.6666667,29.2951435 L21.6666667,21.5381898 C21.6666667,20.4237914 21.7826987,20.0196836 22.0005825,19.6122762 C22.2184664,19.2048688 22.5382021,18.8851331 22.9456095,18.6672492 C23.3530169,18.4493653 23.7571247,18.3333333 24.8715232,18.3333333 Z" id="矩形" fill="#6668B6"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </section>
    )
}

export default Image2