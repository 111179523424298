import React from 'react'

const Image2 = (props) => {
    return (
        <section>
            <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>编组 6</title>
                <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="01-客服-pc" transform="translate(-396.000000, -530.000000)">
                        <g id="编组-6" transform="translate(396.000000, 530.000000)">
                            <path d="M30,0 C46.5685425,-3.04359188e-15 60,13.4314575 60,30 C60,46.5685425 46.5685425,60 30,60 C13.4314575,60 2.02906125e-15,46.5685425 0,30 C-2.02906125e-15,13.4314575 13.4314575,3.04359188e-15 30,0 Z" id="矩形备份-21" fill="#6668B6" opacity="0.900000036"></path>
                            <path d="M23.409713,15 L37.590287,15 C39.8190838,15 40.6272994,15.232064 41.4421143,15.6678318 C42.2569291,16.1035995 42.8964005,16.7430709 43.3321682,17.5578857 C43.767936,18.3727006 44,19.1809162 44,21.409713 L44,39.9762237 C44,41.8171729 42.5076158,43.309557 40.6666667,43.309557 C40.2216706,43.309557 39.7811789,43.2204561 39.371166,43.0475083 L33.3949824,40.5266901 C32.1919377,40.019233 31.7559357,39.8880536 31.2482555,39.8017513 C30.7405753,39.7154491 30.2594247,39.7154491 29.7517445,39.8017513 C29.2440643,39.8880536 28.8080623,40.019233 27.6050176,40.5266901 L21.628834,43.0475083 C19.9326104,43.7629936 17.977534,42.967948 17.2620487,41.2717244 C17.0891009,40.8617115 17,40.4212198 17,39.9762237 L17,21.409713 C17,19.1809162 17.232064,18.3727006 17.6678318,17.5578857 C18.1035995,16.7430709 18.7430709,16.1035995 19.5578857,15.6678318 C20.3727006,15.232064 21.1809162,15 23.409713,15 Z" id="矩形备份-27" fill="#FFFFFF"></path>
                            <path d="M32,15 L40,15 L40,23.1666667 C40,23.626904 39.626904,24 39.1666667,24 L32.8333333,24 C32.373096,24 32,23.626904 32,23.1666667 L32,15 L32,15 Z" id="矩形备份-5" fill="#7577BD"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </section>
    )
}

export default Image2