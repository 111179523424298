import React from 'react'

const Image2 = (props) => {
    return (
        <section>
            <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>编组 13</title>
                <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="01-客服-pc" transform="translate(-396.000000, -682.000000)">
                        <g id="编组-13" transform="translate(396.000000, 682.000000)">
                            <path d="M30,0 C46.5685425,-3.04359188e-15 60,13.4314575 60,30 C60,46.5685425 46.5685425,60 30,60 C13.4314575,60 2.02906125e-15,46.5685425 0,30 C-2.02906125e-15,13.4314575 13.4314575,3.04359188e-15 30,0 Z" id="矩形备份-24" fill="#6668B6"></path>
                            <path d="M23.3309007,15.8333333 L36.6690993,15.8333333 C38.39631,15.8333333 39.0241777,15.9935192 39.669776,16.3042639 C40.3153743,16.6150086 40.8377064,17.076312 41.2258716,17.6785472 C41.6140369,18.2807823 41.8506063,18.8840337 42.0641104,20.5979977 L44.3001724,38.5486061 C44.6415178,41.2888514 42.6968252,43.7869743 39.9565799,44.1283197 C39.7515499,44.1538598 39.5451339,44.1666667 39.3385193,44.1666667 L20.6614807,44.1666667 C17.9000569,44.1666667 15.6614807,41.9280904 15.6614807,39.1666667 C15.6614807,38.9600521 15.6742875,38.7536361 15.6998276,38.5486061 L17.9358896,20.5979977 C18.1493937,18.8840337 18.3859631,18.2807823 18.7741284,17.6785472 C19.1622936,17.076312 19.6846257,16.6150086 20.330224,16.3042639 C20.9758223,15.9935192 21.60369,15.8333333 23.3309007,15.8333333 Z" id="矩形备份-5" fill="#FFFFFF"></path>
                            <path d="M22.3445762,25.9825275 C24.7026406,26.9603277 27.2882967,27.5 30,27.5 C32.7117033,27.5 35.2973594,26.9603277 37.6554238,25.9825275" id="路径" stroke="#6668B6" stroke-width="4.16666667" stroke-linecap="round"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </section>
    )
}

export default Image2