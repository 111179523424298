import React from 'react'

const Image2 = (props) => {
    return (
        <section>
            <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <title>编组 3</title>
                <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="01-客服-pc" transform="translate(-1006.000000, -226.000000)">
                        <g id="编组-3" transform="translate(1006.000000, 226.000000)">
                            <path d="M30,0 C46.5685425,-3.04359188e-15 60,13.4314575 60,30 C60,46.5685425 46.5685425,60 30,60 C13.4314575,60 2.02906125e-15,46.5685425 0,30 C-2.02906125e-15,13.4314575 13.4314575,3.04359188e-15 30,0 Z" id="矩形备份-17" fill="#6668B6"></path>
                            <path d="M20.8113318,16.6666667 L39.1886682,16.6666667 C41.7889312,16.6666667 42.7318494,16.937408 43.6824666,17.4458037 C44.6330839,17.9541994 45.3791339,18.7002494 45.8875296,19.6508667 C46.3959253,20.601484 46.6666667,21.5444022 46.6666667,24.1446652 L46.6666667,35.8553348 C46.6666667,38.4555978 46.3959253,39.398516 45.8875296,40.3491333 C45.3791339,41.2997506 44.6330839,42.0458006 43.6824666,42.5541963 C42.7318494,43.062592 41.7889312,43.3333333 39.1886682,43.3333333 L20.8113318,43.3333333 C18.2110688,43.3333333 17.2681506,43.062592 16.3175334,42.5541963 C15.3669161,42.0458006 14.6208661,41.2997506 14.1124704,40.3491333 C13.6040747,39.398516 13.3333333,38.4555978 13.3333333,35.8553348 L13.3333333,24.1446652 C13.3333333,21.5444022 13.6040747,20.601484 14.1124704,19.6508667 C14.6208661,18.7002494 15.3669161,17.9541994 16.3175334,17.4458037 C17.2681506,16.937408 18.2110688,16.6666667 20.8113318,16.6666667 Z" id="矩形备份-16" fill="#FFFFFF"></path>
                            <path d="M50,37.5 L50,22.5 L40.8333333,22.5 C36.6911977,22.5 33.3333333,25.8578644 33.3333333,30 C33.3333333,34.1421356 36.6911977,37.5 40.8333333,37.5 L50,37.5 Z" id="路径" fill="#6668B6"></path>
                            <path d="M40.8333333,25.8333333 L46.6666667,25.8333333 L46.6666667,25.8333333 L46.6666667,34.1666667 L40.8333333,34.1666667 C38.5321469,34.1666667 36.6666667,32.3011865 36.6666667,30 C36.6666667,27.6988135 38.5321469,25.8333333 40.8333333,25.8333333 Z" id="矩形" fill="#FFFFFF"></path>
                        </g>
                    </g>
                </g>
            </svg>
        </section>
    )
}

export default Image2