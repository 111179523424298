import React, {useState, useEffect, Fragment} from 'react';
import {fetchData} from 'helpers';
import {Icon} from 'antd';
import Image1 from "../../assets/svgIcon/imgs1";
import Image2 from "../../assets/svgIcon/imgs2";
import Image3 from "../../assets/svgIcon/imgs3";
import Image4 from "../../assets/svgIcon/imgs4";
import Image5 from "../../assets/svgIcon/imgs5";
import Image6 from "../../assets/svgIcon/imgs6";
import Image7 from "../../assets/svgIcon/imgs7";
import Image8 from "../../assets/svgIcon/imgs8";
import Starlogo from "../../assets/svgIcon/starlogo";

const toMore = (history, id) => {
  window.sessionStorage.setItem('accountId', id);
  history.push(`/accountproblems?accountid=${id}`)
}

const toQuestionDetail = (history, fkey, id, accountId, title) => {
  window.sessionStorage.setItem('accountId', accountId);
  if (fkey) {
    // history.push(`/accountproblems/accountdetail?fkey=${fkey}&quizTitle=${title}`)
    history.push(`/accountproblems/accountdetail?fkey=${fkey}`)
  } else {
    history.push(`/accountproblems/accountcontent?fid=${id}`);
  }
}

const openQuestionLink = (link)=>{
  window.open(link);
}

const SingleConsult = (props) => {
  const {title, questionList, color1, color2, id, history, iag: Img} = props;
  // return (
  //   <Fragment>
  //     <style jsx>{`
  //         .box {
  //           width: 230px;
  //           height: 298px;
  //           box-sizing: border-box;
  //           padding: 7px;
  //           background: white;
  //           box-shadow:0px 0px 3px 0px rgba(0,0,0,0.14);
  //           position: relative;
  //           border-radius:0px 0px 8px 0px;
  //           overflow: hidden;
  //           margin-right: 12px;
  //           margin-top: 10px;
  //           z-index: 50;
  //         }
  //         .box:nth-child(4n+0) {
  //           margin-right: 0;
  //         }
  //         .type-show {
  //           height: 94px;
  //           // background: linear-gradient(${color1}, ${color2});
  //           font-size: 24px;
  //           color: black;
  //           font-weight: bold;
  //           text-align: center;
  //           line-height: 94px;
  //         }
  //         .quiz-list {
  //           font-size: 14px;
  //           margin-top: 10px;
  //         }
  //         .quiz {
  //           position: relative;
  //           padding-left: 15px;
  //           margin-bottom: 8px;
  //         }
  //         .circle {
  //           width: 4px;
  //           height: 4px;
  //           border-radius: 50%;
  //           background: #d9d9d9;
  //           position: absolute;
  //           top: 50%;
  //           left: 5px;
  //           transform: translateY(-50%);
  //         }
  //         // .more {
  //         //   width: 82px;
  //         //   height:40px;
  //         //   background: #eee;
  //         //   box-shadow: 1px 1px 4px 0px #aaa inset;
  //         //   // font-size: 12px;
  //         //   color: #999;
  //         //   line-height: 40px;
  //         //   text-align: center;
  //         //   position: absolute;
  //         //   right: -10px;
  //         //   bottom: 0;
  //         //   transform: skew(-20deg);
  //         // }
  //         // .more span {
  //         //   display: inline-block;
  //         //   transform: skew(20deg);
  //         //   // font-size: 12px;
  //         // }
  //     `}
  //     </style>
  //     <div className="box"  onClick={() => toMore(history,id)}>
  //       <h1 className="type-show">
  //         {title}
  //       </h1>
  //       <ul className="quiz-list">
  //         {
  //           questionList.map((item, index) => {
  //             return ( <li className="quiz add-pointer" key={index} onClick={() => toQuestionDetail(history,item.fkey,item.id, id, item.title)}><p className="circle"></p><p className="quiz-content">{item.title}</p></li>)
  //           })
  //         }
  //       </ul>
  //       <div className="more add-pointer" onClick={() => toMore(history,id)}>
  //         <span>更多 <Icon type="right" /></span>
  //       </div>
  //     </div>
  //   </Fragment>
  // )
  return (
    <Fragment>
      <style jsx>
        {`
            .bar-text{
             margin-left:20px;
             height:132px;
          }
            .bar-text a{
              font-family: Microsoft YaHei;
              font-size: 20px;
              color: #333333;
              line-height: 28px;
            }
            .bar-text a:hover{
              color:#EA4236;
            }
            .bar-text li{
              font-family: Microsoft YaHei;
              font-size: 14px;
              color: #666666;
              margin-top:10px;
              line-height: 12px;
              float:left;
             cursor: pointer; 
             cursor: hand;
            }
            .bar{
              height:132px;
              display:flex;
              padding-left:36px;
              padding-top:36px;
              background: #ffffff;
              box-shadow: 0 2px 20px 0 #0000000f;
              border-radius: 10px;
            }
           .left:nth-child(odd){
             margin-right:20px;
           }
           .left{
           width:590px;  
           margin-bottom:20px;
           }
      
            `}
      </style>
      <div className="left">
        <div className="bar">
          <div>
            <Img/>
          </div>
          <div className="bar-text">
            <a onClick={() => toMore(history, id)}>{title}</a>
            <ul>
              {questionList.map((item, index) => {
                return (
                  <li key = {index} onClick={() => (item?.type === '2' && item?.link) ? openQuestionLink(item.link):toQuestionDetail(history, item.fkey, item.id, id, item.title)}>
                      {((index + 1) !== questionList.length) ? item.title + '、' : item.title}
                  </li>
                )
              })
              }
            </ul>
          </div>
        </div>
      </div>

    </Fragment>
  )
}


const AllKindsConsult = (props) => {
  const arr1 = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8];
  const arr2 = [
    'https://p0.qhimg.com/t0135b4b86eeb68f9b1.png',
    'https://p0.qhimg.com/t01ac749ce8289ea5fe.png',
    'https://p0.qhimg.com/t01e1ce2415ebb88561.png',
    'https://p0.qhimg.com/t0122973e266e84bc2f.png',
    'https://p0.qhimg.com/t01879f0742029069fe.png',
    'https://p0.qhimg.com/t0115b1aa1090929bc0.png',
    'https://p0.qhimg.com/t0114abd229ed38946f.png',
    'https://p0.qhimg.com/t011d25ed5d40c1625b.png'
  ]
  const [questionList, setQuestionList] = useState([]);
  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    let mark = params.get('mark');
    console.log('mark',mark);
    fetchData('getQuestionCateList').then((res) => {
      for (let i = 0; i < res.data.length; i++) {
        res.data[i].img = arr1[i % arr1.length];
        mark === 'starworld-f08eb42647e2aa91a5c729e547ba49b3' && (res.data[i].img = ()=>{
          return (<div>
            <img style={{width:60,height:60}} src={arr2[i%arr2.length]} />
          </div>)
        })
      }
      setQuestionList(res.data);
      let breads = res.data.map(item => {
        return {
          id: item.id,
          title: item.title
        }
      });
      breads.toJSON = undefined;
      window.sessionStorage.setItem('questionBreads', JSON.stringify(breads))
    }).catch((err) => {
      console.log(err);
    });
  }, []);
  console.log(questionList);
  return (
    <section>
      <style jsx>{`
             .box {

               display: flex;
               flex-wrap: wrap;
               width:1200px;
               // justify-content: space-between;
             }
         `}
      </style>
      <section className="box">
        {
          questionList.map((item, index) => {
            return (
              <SingleConsult
                key={index}
                title={item.title}
                id={item.id}
                history={props.history}
                questionList={item.question_list}
                iag={item.img}
              />
            )
          })
        }
      </section>
    </section>
  )
}

function mapStateToProps(state) {
  return {
    questionBreads: state.questionReducer.questionBreads,
  }
}

export default AllKindsConsult