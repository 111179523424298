import React, { useEffect, useState } from 'react'
import BaseIcon from 'assets/svgIcon/baseIcon';
import { fetchData } from 'helpers';

const SingleProblem = (props) => {
    const { title, description, iconName } = props;
    const style = {
        width: '330px',
        display: 'flex',
        // alignItems: 'center',
    }
    return (
        <section style={style} className="add-pointer">
            <style jsx>
                {`
                    .content {
                        margin-left: 10px;
                    }
                    .title {
                        font-weight: 600;
                        font-size: 16px;
                        color: #333;
                    }
                    .explaination {
                        color: #666;
                    }
                `}
            </style>
            <BaseIcon iconName={iconName} width={50} height={50} />
            <div className="content">
                <p className="title">{title}</p>
                <p className="explaination">{description}</p>
            </div>
        </section>
    )
}

const toAccountDetail = (history, fkey,id, quizTitle) => {
    if (fkey) {
        // history.push(`/accountproblems/accountdetail?fkey=${fkey}&quizTitle=${quizTitle}`)
        history.push(`/accountproblems/accountdetail?fkey=${fkey}&quizTitle=${quizTitle}`)
    } else {
        history.push(`/accountproblems/accountcontent?fid=${id}&quizTitle=${quizTitle}`);
    }
}
let randoms = [];
function getRandom(min, max) {
    let num = Math.floor(Math.random()*(max - min)) + min;
    if (randoms.includes(num)) {
        return getRandom(min, max);
    }
    randoms.push(num);
    if (randoms.length > 9) {
        randoms = [];
    }
    return num;
}

const AccountProblems = (props) => {
    const accountId = new URLSearchParams(props.location.search).get('accountid');
    const [questionList, setQuestionList] = useState([]);
    useEffect(() => {
        fetchData('getQuestionList', { id: accountId}).then((res) => {
            let list = res.data.question_list;
            setQuestionList(list);
        }).catch((err) => {
            
        });
    }, [accountId])
    const style = {
        border: '1px solid #e9e9e9',
        minHeight: '500px',
        maxHeight: '700px',
        overflowY: 'auto',
        paddingTop: '60px',
        background: 'white',
    }
    const openQuestionLink = (link)=>{
        window.open(link);
    }
    let iconNames = ['AccountFangchenmi','AccountFuwu','AccountGaowei','AccountJiefeng','AccountQian','AccountShouji','AccountTousu','AccountWaigua','AccountWanhui','AccountZhapian',]
    return (
        <section style={style}>
            <style jsx>{`
                .contents {
                    // display: flex;
                    // flex-wrap: wrap;
                    // justify-content: space-between;
                    overflow: hidden;
                }
                .content {
                    margin-bottom: 40px;
                    margin-left: 60px;
                    margin-right: 40px;
                    height: 70px;
                }
            `}
            </style>
            <ul className="contents">
                {questionList && questionList.map((item,index) => {
                    let iconNum = getRandom(0,iconNames.length);
                    return (<li className="content fl" key={item.id} onClick={() => (item?.type === '2' && item?.link) ? openQuestionLink(item.link):toAccountDetail(props.history,item.fkey,item.id,item.title)}><SingleProblem {...item} iconName={iconNames[iconNum]} /></li>)
                })}
            </ul>
        </section>
    )
}

export default AccountProblems