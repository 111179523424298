import React, { useEffect } from 'react'
// import Announcement from './announcement';
// import HotQuiz from './hotQuiz';
// import Banner from './banner';
import AllKindsConsult from './allKindsConsult';
let isInitPush = true;

const CenterPages = (props) => {


  // if (isInitPush) {
  //
  //   isInitPush = false;
  // }
    
    
    return (
      <section>
         <style jsx>{`
             .banner {
               margin-top: 10px;
             }
             .hot-quiz {
               margin-left: 10px;
             }
         `}
         </style>
         <section className="center-container">
            {/*<div style={{display: 'flex',justifyContent: 'space-between'}}>*/}
              {/*<Announcement />*/}
              {/*<HotQuiz />*/}
            {/*</div>*/}
            <div className="consults">
              <AllKindsConsult history={props.history} />
            </div>
         </section>
      </section>
    )
}
                          
export default CenterPages